<template>
  <div class="row" style="width: 100%;">
    <div class="col-12 col-md-3">
      <SidebarMenu />
    </div>
    <div class="col-12 col-md-9 main-content" v-if="isAuthenticated">
      <div class="welcome-message">
        <h2>Welcome, {{ user.name }}</h2>
      </div>
      <div v-for="(organization, index) in dbOrgInfo" :key="index">
        <div :class="['active-org-info', { expanded: expandedOrgIndex === index }]">
          <div class="info-header">
            <div>
              <h1 class="org-details">{{ organization.organizationName }}</h1>
              <p class="org-details">{{ organization.organizationEmail }}</p>
            </div>
            <div class="expand-container" @click="toggleExpand(index)">
              <i :class="expandedOrgIndex === index ? 'bi bi-chevron-down' : 'bi bi-chevron-right'"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/authStore';
import { useUserStore } from '@/stores/user';
import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  name: 'Home',
  components: {
    SidebarMenu,
  },
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const user = ref(authStore.user);
    const isAuthenticated = ref(authStore.isAuthenticated);
    const dbUserInfo = ref(null);
    const dbOrgInfo = ref([]);
    const expandedOrgIndex = ref(null);

    const getUserInfo = async () => {
      try {
        const response = await fetch(`/user/get-user-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error((await response.json()).error || 'Unknown error');
        dbUserInfo.value = await response.json();
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    const getOrganizationInfo = async () => {
      try {
        const response = await fetch(`/organization/get-organization-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error((await response.json()).error || 'Unknown error');
        dbOrgInfo.value = await response.json();
      } catch (error) {
        console.error('Failed to fetch organization info:', error);
      }
    };

    const toggleExpand = (index) => {
      expandedOrgIndex.value = expandedOrgIndex.value === index ? null : index;
    };

    onMounted(async () => {
      if (isAuthenticated.value) {
        await getUserInfo();
        if (dbUserInfo.value?.affiliatedOrg) {
          await getOrganizationInfo();
        }
      }
    });

    return {
      user,
      isAuthenticated,
      dbUserInfo,
      dbOrgInfo,
      expandedOrgIndex,
      toggleExpand,
    };
  },
};
</script>

<style scoped>
.navbar {
  margin: 0;
  padding-top: 10px;
  width: 100%;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.main-content {
  background-color: #f4f6f8;
  min-height: 100vh;
}

.welcome-message {
  display: flex;
  justify-content: center;
}

.welcome-message h2 {
  font-weight: bold;
  color: #195aa0;
  text-align: center;
  margin-bottom: 20px;
}

.active-org-info {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  font-size: 16px;
  color: #333333;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 80px;
  display: flex;
  flex-direction: column;
}

.active-org-info.expanded {
  max-height: 300px;
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}

.expand-container {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.org-details {
  font-weight: 500;
  color: #195aa0;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .main-content {
    margin: 0 auto;
    width: 100%;
  }

  .welcome-message h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .active-org-info {
    flex-direction: column;
    text-align: center;
  }

  .org-action-buttons {
    margin: 10px 0;
    width: 100%;
    padding: 10px;
  }

  .org-button-container {
    flex-direction: column;
    gap: 10px;
  }

  .org-form {
    padding: 15px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 576px) {
  .org-action-buttons {
    padding: 8px;
    font-size: 14px;
  }

  .welcome-message h2 {
    font-size: 20px;
  }

  .active-org-info {
    padding: 15px;
  }
}
</style>
