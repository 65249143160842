<template>
  <div id="app">
    <Navbar />
    <nav class="hide-nav">
      <router-link to="/"></router-link>
      <router-link to="/Home"></router-link>
    </nav>
    <router-view></router-view>
    <CustomerSupportInterface></CustomerSupportInterface>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import CustomerSupportInterface from './components/CustomerSupportInterface.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    CustomerSupportInterface
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&display=swap');
html, body, #app {
  font-family: 'Kantumruy Pro', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0; 
  background-color: #f4f6f8;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.hide-nav {
  height: 0;
  width: 0;
  overflow: hidden;
  display: inline-block;
}

.btn-default {
  background-color: #195aa0 !important;
  color: white !important;
}

.btn-default:hover {
  background-color: #195aa086 !important;
  color: white;
}
</style>
