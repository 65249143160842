<template>
  <div class="page-container">
    <div class="main-container">
      <div class="col-12 faq-header-container">
        <h1 class="faq-details">FAQ For {{ dbOrgInfo.organizationName }}</h1>
      </div>
      <div v-for="(faq, index) in faqList" :key="index" class="question-container col-12">
        <div class="question-list-container row">
          <div class="col-sm-1 col-md-1 label-col">
            <h2>Q:</h2>
          </div>
          <div class="col-sm-10 col-md-9">
            <textarea :disabled="!faq.isQuestionEditable" rows="3" class="form-control editable-textarea" v-model="faq.question"></textarea>
          </div>
          <i class="edit-icon bi bi-pencil col-md-2" v-if="!faq.isQuestionEditable && !mergeQuestionsActive" @click="toggleEditMode(faq.questionId)"></i>
          <i v-if="mergeQuestionsActive" @click="addQuestionForMerge(faq)" style="font-size: 1.5rem;" class="addition-icon bi bi-plus-lg col-md-2"></i>
        </div>
        <div class="answer-list-container row">
          <div class="col-sm-1 col-md-1 label-col">
            <h2>A:</h2>
          </div>
          <div class="col-sm-10 col-md-9">
            <textarea :disabled="!faq.isQuestionEditable" rows="4" class="form-control editable-textarea" v-model="faq.answer"></textarea>
          </div>
          <i class="save-icon bi bi-check2 col-md-2" style="font-size: 1.5rem;" v-if="faq.isQuestionEditable" @click="editQuestion(faq)"></i>
          <i class="trash-icon bi bi-trash delete-question col-md-2" style="font-size: 1.5rem;" v-if="!faq.isQuestionEditable" @click="showDialog(faq)"></i>
        </div>
      </div>
      <div class="question-container col-12">
        <div class="row">
          <div class="col-sm-1 col-md-1 label-col">
            <h2>Q:</h2>
          </div>
          <div class="col-sm-10 col-md-9">
            <textarea v-model="newFaq.question" class="form-control editable-textarea" placeholder="Enter your question"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-1 col-md-1 label-col">
            <h2>A:</h2>
          </div>
          <div class="col-sm-10 col-md-9">
            <textarea v-model="newFaq.answer" class="form-control editable-textarea" rows="1" placeholder="Enter your answer"></textarea>
          </div>
        </div>
      </div>
      <button class="btn btn-primary mt-3" @click="addAndPostFaq">Add Question</button>
    </div>
    <div class="control-panel-container">
      <div class="control-panel-header">
        <h1>Control Panel</h1>
      </div>
      <div class="control-panel-stats">
        <div style="text-align: center;">
          <p class="unanswered-questions">{{ faqStats.unansweredQuestions }}</p>
          <p>Unanswered Questions</p>
        </div>
        <div style="text-align: center;">
          <p class="answered-questions">{{ faqStats.answeredQuestions }}</p>
          <p>Answered Questions</p>
        </div>
      </div>
      <div class="control-panel-actions">
        <div v-if="mergeQuestionsActive" class="control-panel-header">
          <h5>Questions to Combine</h5>
        </div>
        <p v-if="mergeQuestionsActive">If questions are similar, feel free to combine them by clicking the + next to the question.</p>
        <div v-if="mergeQuestionsActive" v-for="(mergeQuestion, index) in mergeQuestions" :key="index" class="combined-questions col-12">
          <strong>{{ mergeQuestion.question }}</strong>
        </div>
        <div v-if="mergeQuestionsActive" class="control-panel-header">
          <h5>Combined Answers</h5>
        </div>
        <p v-if="mergeQuestionsActive">
          We have combined all the answers for the questions you want to merge. Please edit this to reflect the correct answer.
        </p>
        <textarea v-if="mergeQuestionsActive" class="combined-answers" v-model="mergedAnswers"></textarea>
        <button v-if="mergeQuestionsActive" @click="confirmMergeQuestions" class="confirm-merge-btn btn btn-default text-start">
          Confirm Merge
        </button>
        <button 
          @click="activateMergeQuestions" 
          :class="['btn btn-default text-start', mergeQuestionsActive ? 'cancel-merge-btn' : '']">
          {{ mergeQuestionsActive ? 'Cancel Merge' : 'Merge Questions' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import { useToast } from 'vue-toastification';


export default {
  name: 'Faq',
  components: {
  },
  setup() {
    const toast = useToast();
    const toastOptions = { position: 'bottom-left', timeout: 1300 };
    const authStore = useAuthStore();

    const dialog = inject('$dialog');

    const router = useRouter();
    const route = useRoute();
    
    const user = ref(authStore.user);
    const isAuthenticated = ref(authStore.isAuthenticated)

    const dbUserInfo = ref(null);
    const dbOrgInfo = ref(null);
    const faqList = ref([]);
    const newFaq = ref({ question: '', answer: '' });

    const mergeQuestionsActive = ref(false);
    const mergeQuestions = ref([])
    const mergedAnswers = ref('')

    const faqStats = ref(null)
    
    const getUserInfo = async () => {
      try {
        const userId = user.value.sub;
        const response = await fetch(`/user/get-user-info?userId=${encodeURIComponent(userId)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }
        dbUserInfo.value = await response.json();
      } catch (error) {
        alert(error);
      }
    }

    const getOrganizationInfo = async () => {
      try {
        const userId = encodeURIComponent(user.value.sub);
        const response = await fetch(`/organization/get-organization-info?userId=${userId}&orgId=${route.params.organizationId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }
        dbOrgInfo.value = await response.json();
        dbOrgInfo.value = dbOrgInfo.value[0];


      } catch (error) {
        alert(error.message || 'An error occurred');
      }
    }


    const postFaq = async (faq, organizationId) => {
      try {
        const response = await fetch('/faq/add-question', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...faq,
            organizationId: organizationId
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to save FAQ');
        }

        const savedFaq = await response.json();

        const updatedFaqs = await getExistingQuestions();

        initializeFaqList(updatedFaqs);

        return savedFaq;
      } catch (error) {
        toast.warning(`${error.message}`,toastOptions);
        return null;
      }
    };

    const addAndPostFaq = async () => {
      if (newFaq.value.question.trim() === '' || newFaq.value.answer.trim() === '') {
        alert('Please enter both a question and an answer.');
        return;
      }
      const savedFaq = await postFaq(newFaq.value, dbOrgInfo.value.organizationId);

      if (savedFaq) {
        newFaq.value = { question: '', answer: '' };
        faqStats.value = await getFAQStatistics();
      }
      
    };

    const getExistingQuestions = async () => {
      try {
        const response = await fetch(`/faq/faq-list?organizationId=${encodeURIComponent(dbOrgInfo.value.organizationId)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        alert(error);
        return [];  
      }
    };

    const getFAQStatistics = async () => {
      try {
        const response = await fetch(`/faq/faq-statistics?organizationId=${encodeURIComponent(dbOrgInfo.value.organizationId)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        alert(error);
        return [];  
      }
    };

    const initializeFaqList = (faqData) => {
      faqList.value = [];

      faqList.value = faqData.map(faq => ({
        ...faq,
        isQuestionEditable: false
      }));
    };

    const toggleEditMode = (questionId) => {
      faqList.value.forEach(faq => {
        faq.isQuestionEditable = faq.questionId === questionId
      })
    }

    const editQuestion = async (question) => {
      try {
        const response = await fetch(`/faq/update-question`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              questionId: question.questionId,
              question: question.question,
              answer: question.answer,
              organizationId: dbOrgInfo.value.organizationId
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update FAQ');
        }

        const updatedFaq = await response.json();

        const updatedFaqs = await getExistingQuestions();

        initializeFaqList(updatedFaqs);
        faqStats.value = await getFAQStatistics();
        return updatedFaq;
      } catch (error) {
          alert(`Error: ${error.message}`);
          return null;
      }
    }

    const activateMergeQuestions = () => {
      mergeQuestionsActive.value = !mergeQuestionsActive.value;
    }

    const addQuestionForMerge = (question) => {
      if (!mergeQuestions.value.some(q => q.questionId === question.questionId)) {
        mergeQuestions.value.push(question)
        mergedAnswers.value += (mergedAnswers.value ? ' ' : '') + question.answer;
      }else{
        alert('question already set for merge')
      }
    }

    const confirmMergeQuestions = async () => {
      try {
        const response = await fetch(`/faq/merge-questions`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              questionsToMerge: mergeQuestions.value.map(question => ({
                questionId:question.questionId,
                question:question.question
              })),
              answer:mergedAnswers.value,
              organizationId: dbOrgInfo.value.organizationId
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to merge questions.');
        }else{
          alert('questions merged')
          mergeQuestionsActive.value = false;
          mergedAnswers.value = ''
          mergeQuestions.value = []
          const existingFaqs = await getExistingQuestions();
          initializeFaqList(existingFaqs);
        }

      } catch (error) {
          alert(`Error: ${error.message}`);
          return null;
      }
    }

    onMounted(async () => {
      if(isAuthenticated.value){
        await getUserInfo();
        if (dbUserInfo.value) {
          await getOrganizationInfo();
        }

        const existingFaqs = await getExistingQuestions();
        faqStats.value = await getFAQStatistics();
        initializeFaqList(existingFaqs);
      }
    });

    const showDialog = (question) => {
      dialog({
        message: `Are you sure you want to delete this question? This cannot be undone.`,
        buttons: ['cancel', 'confirm'],
        callbacks: {
          cancel: () => {},
          confirm: () => {
            deleteQuestion(question)
          },
        },
        boxClass: 'my-custom-box',
        messageClass: 'my-custom-message',
        buttonsClass: 'my-custom-buttons',
        css: { 
          default: true,
          wrapper: true,
          darken:.9
        }
      });
    };

    const deleteQuestion = async (question) => {
      try{
        const response = await fetch('/faq/delete-question', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            questionId:question.questionId,
            organizationId: dbOrgInfo.value.organizationId
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to save FAQ');
        }

        const existingFaqs = await getExistingQuestions();
        faqStats.value = await getFAQStatistics();
        initializeFaqList(existingFaqs);
      }catch (error) {
        console.error(error)
      }
    }


    return {
      dbUserInfo,
      dbOrgInfo,
      faqList,
      newFaq,
      addAndPostFaq,
      toggleEditMode,
      editQuestion,
      faqStats,
      mergeQuestionsActive,
      activateMergeQuestions,
      addQuestionForMerge,
      mergeQuestions,
      mergedAnswers,
      confirmMergeQuestions,
      showDialog
    };
  },
};
</script>

<style>
.page-container {
  display:flex;
  
}
.main-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: #333333;
  width: 100%;
  max-width: 800px;
}

.control-panel-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: #333333;
  width: 100%;
  max-width: 600px;
  position: sticky;
  height: 350px;
  top: 30%;
  overflow-y: auto;
}


.control-panel-header {
  text-align:center;
  width:100%;
  color:#1584ea;
}

.faq-details {
  font-weight: 500;
  color: #1584ea;
}

.faq-header-container {
  display: flex;
  justify-content: center;
}
.editable-textarea {
  border: none;
  background-color: transparent;
  resize: vertical;
  outline: none;
  width: 100%;
  min-height: 50px;
  overflow: auto;
}

.editable-textarea:focus {
  border: 1px solid #ced4da;
  background-color: white;
}

.editable-textarea:disabled {
  color: #495057;
}

.edit-icon, .save-icon, .trash-icon {
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addition-icon {
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-col {
  text-align: right;
}

.answer-list-container {
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: 2px solid #9dccf6;
}

.question-list-container {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .faq-header-container h1 {
    font-size: 1.5rem;
  }

  .label-col {
    text-align: left;
  }

  .edit-icon, .save-icon {
    font-size: 1.2rem;
    margin-top: 10px;
  }

  .main-container {
    padding: 15px;
    width: 90%;
  }
}

.control-panel-stats {
  display:flex;
  justify-content: space-around;
}
.answered-questions {
  font-size: 4rem;
  color:green;
}
.unanswered-questions {
  font-size: 4rem;
  color:red;
}

.control-panel-actions {
  width:100%;
}

.btn-default{
  max-width: none;
  text-align: center !important;
}
.combined-answers {
  width:100%;
}
.combined-questions {
  width:100%;
  margin-bottom: 20px;
}
.confirm-merge-btn {
  background-color: green !important;
  margin-bottom:10px;
}
.cancel-merge-btn {
  background-color: rgba(185, 0, 0, 0.841) !important;
}
.confirm-merge-btn:hover {
  background-color: rgb(99, 164, 99) !important;
  color: white;
}
.cancel-merge-btn:hover {
  background-color: rgba(207, 95, 95, 0.841) !important;
  color: white;
}
.delete-question {
  color: red;
  cursor: pointer;
  font-size: 22px;
}

.my-custom-wrapper {
  background-color: rgba(0, 0, 0, 0.7); /* Custom darkening */
}

.my-custom-box {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  width:500px;
  margin-left:auto;
  margin-right:auto;
  margin-top:200px;
}

.my-custom-message {
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom:10px;
}

.my-custom-buttons {
  display: flex;
  justify-content: space-around;
}

.my-custom-buttons button:first-child {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: red; 
  color: white;
  cursor: pointer;
}

/* Target the second button */
.my-custom-buttons button:nth-child(2) {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: green; 
  color: white;
  cursor: pointer;
}

.my-custom-buttons .vue3-dialog-button:hover {
  background-color: #218838;
}
</style>