import { defineStore } from 'pinia';

export const useSnippetsStore = defineStore('snippets', {
  state: () => ({
    codeSnippets: {
      vuejs: `
<template>
  <div class="chat-container">
    <button class="chat-icon" @click="toggleChatWindow">
      <i style="font-size:2rem;" class="bi bi-chat-dots"></i>
    </button>

    <transition name="fade">
      <div v-if="isChatOpen" class="chat-window">
        <div class="chat-header">
          <p>Pulse Chat</p>
          <button class="close-btn" @click="toggleChatWindow">✖️</button>
        </div>

        <div class="chat-messages">
          <div
            v-for="(msg, index) in messages"
            :key="index"
            :class="{'user-message': msg.isUser, 'agent-message': !msg.isUser}"
          >
            {{ msg.text }}
          </div>
        </div>

        <div class="chat-input">
          <input
            v-model="newMessage"
            @keyup.enter="sendMessage"
            type="text"
            placeholder="Type your message..."
          />
          <button @click="sendMessage">Send</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'ChatComponent',
  setup() {

    const isChatOpen = ref(false);
    const messages = ref([]);
    const newMessage = ref('');
    const organizationId = 8;

    const toggleChatWindow = () => {
      isChatOpen.value = !isChatOpen.value;
    };

    const sendMessage = async () => {
      if (newMessage.value.trim() !== '') {
        const userMessage = newMessage.value;
        messages.value.push({ text: userMessage, isUser: true });
        newMessage.value = '';

        try {
          const response = await fetch('/chatbot/client/ask-question', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              chatBotQuestion: userMessage,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to get response from the chatbot');
          }

          const data = await response.json();
          messages.value.push({ text: data.message, isUser: false });
          if(data.emailNeeded){
            messages.value.push({text: 'What is an email we can reach you at with a response to your question?', isUser: false})
          }
        } catch (error) {
          console.error('Error:', error);
          messages.value.push({
            text: 'Sorry, something went wrong. Please try again later.',
            isUser: false,
          });
        }
      }
    };

    return {
      isChatOpen,
      messages,
      newMessage,
      toggleChatWindow,
      sendMessage,
    };
  },
};
</script>

<style scoped>
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chat-icon {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.chat-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.chat-window {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 320px;
  max-height: 450px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 1;
  animation: slide-up 0.3s ease-out;
}

.chat-header {
  background-color: #f0f0f0;
  color: #333333;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.chat-messages {
  padding: 10px 15px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: #fafafa;
  font-size: 0.9rem;
}

.user-message {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 15px 15px 0 15px;
  margin-bottom: 8px;
  align-self: flex-end;
  max-width: 75%;
  word-wrap: break-word;
}

.agent-message {
  background-color: #e6e6e6;
  color: #333333;
  padding: 8px 12px;
  border-radius: 15px 15px 15px 0;
  margin-bottom: 8px;
  align-self: flex-start;
  max-width: 75%;
  word-wrap: break-word;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f8f8f8;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-size: 0.9rem;
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
      `,
      react: `

import React, { useState } from 'react';

const ChatComponent = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isCollectingEmail, setIsCollectingEmail] = useState(false);
  const [lastQuestionId, setLastQuestionId] = useState(null);

  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };

  const sendMessage = async () => {
    if (newMessage.trim() !== '') {
      const userMessage = newMessage;
      setMessages([...messages, { text: userMessage, isUser: true }]);
      setNewMessage('');

      if (isCollectingEmail) {
        await submitEmail(userMessage);
        setIsCollectingEmail(false);
      } else {
        await sendQuestionToChatbot(userMessage);
      }
    }
  };

  const sendQuestionToChatbot = async (userMessage) => {
    try {
      const response = await fetch('/chatbot/client/ask-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chatBotQuestion: userMessage }),
      });

      if (!response.ok) {
        throw new Error('Failed to get a response from the chatbot');
      }

      const data = await response.json();
      setMessages([...messages, { text: data.message, isUser: false }]);

      if (data.emailNeeded) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'What is an email we can reach you at with a response to your question?', isUser: false }
        ]);
        setLastQuestionId(data.questionId);
        setIsCollectingEmail(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages([...messages, { text: 'Sorry, something went wrong. Please try again later.', isUser: false }]);
    }
  };

  const submitEmail = async (email) => {
    try {
      const emailResponse = await fetch('/chatbot/client/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questionId: lastQuestionId,
          email: email,
        }),
      });

      if (!emailResponse.ok) {
        throw new Error('Failed to submit email');
      }

      const data = await emailResponse.json();
      setMessages([...messages, { text: data.message, isUser: false }]);
      setLastQuestionId(null);
    } catch (error) {
      console.error('Error:', error);
      setMessages([...messages, { text: 'Sorry, something went wrong. Please try again later.', isUser: false }]);
      setLastQuestionId(null);
    }
  };

  return (
    <div style={styles.chatContainer}>
      <button style={styles.chatIcon} onClick={toggleChatWindow}>
        <i style={{ fontSize: '2rem' }} className="bi bi-chat-dots"></i>
      </button>

      {isChatOpen && (
        <div style={styles.chatWindow}>
          <div style={styles.chatHeader}>
            <p>Pulse Chat</p>
            <button style={styles.closeBtn} onClick={toggleChatWindow}>✖️</button>
          </div>

          <div style={styles.chatMessages}>
            {messages.map((msg, index) => (
              <div
                key={index}
                style={msg.isUser ? styles.userMessage : styles.agentMessage}
              >
                {msg.text}
              </div>
            ))}
          </div>

          <div style={styles.chatInput}>
            <input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyUp={(e) => e.key === 'Enter' && sendMessage()}
              type="text"
              placeholder="Type your message..."
              style={styles.inputField}
            />
            <button style={styles.sendButton} onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  chatContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
  },
  chatIcon: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s, box-shadow 0.2s',
  },
  chatWindow: {
    position: 'absolute',
    bottom: '80px',
    right: '0',
    width: '320px',
    maxHeight: '450px',
    backgroundColor: '#ffffff',
    borderRadius: '15px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    opacity: 1,
    animation: 'slide-up 0.3s ease-out',
  },
  chatHeader: {
    backgroundColor: '#f0f0f0',
    color: '#333333',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
  },
  chatMessages: {
    padding: '10px 15px',
    overflowY: 'auto',
    flexGrow: 1,
    backgroundColor: '#fafafa',
    fontSize: '0.9rem',
  },
  userMessage: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '8px 12px',
    borderRadius: '15px 15px 0 15px',
    marginBottom: '8px',
    alignSelf: 'flex-end',
    maxWidth: '75%',
    wordWrap: 'break-word',
  },
  agentMessage: {
    backgroundColor: '#e6e6e6',
    color: '#333333',
    padding: '8px 12px',
    borderRadius: '15px 15px 15px 0',
    marginBottom: '8px',
    alignSelf: 'flex-start',
    maxWidth: '75%',
    wordWrap: 'break-word',
  },
  chatInput: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ddd',
    backgroundColor: '#f8f8f8',
  },
  inputField: {
    flexGrow: 1,
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #ccc',
    marginRight: '10px',
    fontSize: '0.9rem',
  },
  sendButton: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    padding: '10px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  closeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  '@keyframes slide-up': {
    from: {
      transform: 'translateY(100%)',
      opacity: 0,
    },
    to: {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
};

export default ChatComponent;
 `,nodejs: `
const express = require('express');
const router = express.Router();

//this is the vantage pulse client endpoint
router.post('/client/ask-question', async (req, res) => {
    try {
        const { chatBotQuestion } = req.body;
        
        const response = await fetch('http://vantagevsp.com/chatbot/ask-question', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                chatBotQuestion,
                apiKey: process.env.PULSE_CHAT_KEY 
            })
        });

        if (!response.ok) {
            throw new Error();
        }

        const data = await response.json();
        res.status(200).json({ message: data.message, emailNeeded: data.emailNeeded, questionId: data.questionId });
    } catch (error) {
        console.error('Error:', error);
        res.status(500).json({ error: 'Internal server error' });
    }
});

router.post('/client/submit-email', async (req, res) => {
    try {
        const { questionId, email } = req.body;
        
        const response = await fetch('http://vantagevsp.com/chatbot/submit-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                questionId: questionId,
                email: email 
            })
        });

        if (!response.ok) {
            throw new Error();
        }

        const data = await response.json();
        res.status(200).json({ message: data.message });
    } catch (error) {
        console.error('Error:', error);
        res.status(500).json({ error: 'Internal server error' });
    }
});

module.exports = router;

 `, wordpress: `
 <div class="chat-container">
    <button class="chat-icon" id="chat-icon">
        <i style="font-size:2rem;" class="bi bi-chat-dots"></i>
    </button>

    <div id="chat-window" class="chat-window" style="display: none;">
        <div class="chat-header">
            <p>Pulse Chat</p>
            <button class="close-btn" id="close-chat">✖️</button>
        </div>

        <div class="chat-messages" id="chat-messages">
            <!-- Messages will appear here -->
        </div>

        <div class="chat-input">
            <input id="new-message" type="text" placeholder="Type your message..."/>
            <button id="send-message">Send</button>
        </div>
    </div>
</div>

<style>
.chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chat-icon {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.chat-icon:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.chat-window {
    position: absolute;
    bottom: 80px;
    right: 0;
    width: 320px;
    max-height: 450px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    opacity: 1;
    animation: slide-up 0.3s ease-out;
}

.chat-header {
    background-color: #f0f0f0;
    color: #333333;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.chat-messages {
    padding: 10px 15px;
    overflow-y: auto;
    flex-grow: 1;
    background-color: #fafafa;
    font-size: 0.9rem;
}

.user-message {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 15px 15px 0 15px;
    margin-bottom: 8px;
    align-self: flex-end;
    max-width: 75%;
    word-wrap: break-word;
}

.agent-message {
    background-color: #e6e6e6;
    color: #333333;
    padding: 8px 12px;
    border-radius: 15px 15px 15px 0;
    margin-bottom: 8px;
    align-self: flex-start;
    max-width: 75%;
    word-wrap: break-word;
}

.chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
    background-color: #f8f8f8;
}

.chat-input input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
    font-size: 0.9rem;
}

.chat-input button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.chat-input button:hover {
    background-color: #0056b3;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>

<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
<script>
jQuery(document).ready(function ($) {

    let isCollectingEmail = false;
    let lastQuestionId = null;

    $('#chat-icon').click(function () {
        $('#chat-window').fadeToggle();
    });

    $('#close-chat').click(function () {
        $('#chat-window').fadeToggle();
    });

    $('#send-message').click(async function () {
        const newMessage = $('#new-message').val().trim();

        if (newMessage !== '') {
            appendUserMessage(newMessage);
            $('#new-message').val('');

            if (isCollectingEmail) {
                await submitEmail(newMessage);
                isCollectingEmail = false;
            } else {
                await sendQuestionToChatbot(newMessage);
            }
        }
    });

    async function sendQuestionToChatbot(userMessage) {
        try {
            const response = await $.post('/chatbot/client/ask-question', {
                chatBotQuestion: userMessage
            });

            appendAgentMessage(response.message);

            if (response.emailNeeded) {
                appendAgentMessage('What is an email we can reach you at with a response to your question?');
                lastQuestionId = response.questionId;
                isCollectingEmail = true;
            }
        } catch (error) {
            console.error('Error:', error);
            appendAgentMessage('Sorry, something went wrong. Please try again later.');
        }
    }

    async function submitEmail(email) {
        try {
            const response = await $.post('/chatbot/client/submit-email', {
                questionId: lastQuestionId,
                email: email
            });

            appendAgentMessage(response.message);
            lastQuestionId = null;
        } catch (error) {
            console.error('Error:', error);
            appendAgentMessage('Sorry, something went wrong. Please try again later.');
            lastQuestionId = null;
        }
    }

    function appendUserMessage(message) {
        $('#chat-messages').append(\`<div class="user-message">\${message}</div>\`);
    }

    function appendAgentMessage(message) {
        $('#chat-messages').append(\`<div class="agent-message">\${message}</div>\`);
    }

});
</script>`, wordpress_backend: `
<?php
/**
 * Plugin Name: Pulse Chatbot
 * Description: A chatbot plugin for WordPress.
 * Version: 1.0
 * Author: Your Name
 */

if (!defined('ABSPATH')) {
    exit; // Exit if accessed directly.
}

// Enqueue the frontend scripts and styles
function pulse_chatbot_enqueue_scripts() {
    wp_enqueue_script('pulse-chatbot-js', plugins_url('chatbot.js', __FILE__), array('jquery'), null, true);
    wp_enqueue_style('pulse-chatbot-css', plugins_url('chatbot.css', __FILE__));
}
add_action('wp_enqueue_scripts', 'pulse_chatbot_enqueue_scripts');

// Handle the AJAX request for asking a question
function pulse_chatbot_ask_question() {
    $chatBotQuestion = sanitize_text_field($_POST['chatBotQuestion']);

    // Here you would interact with the external chatbot API
    $response = wp_remote_post('http://vantagevsp.com/chatbot/ask-question', array(
        'method' => 'POST',
        'body' => json_encode(array(
            'chatBotQuestion' => $chatBotQuestion,
            'apiKey' => 'your_api_key_here'
        )),
        'headers' => array(
            'Content-Type' => 'application/json'
        )
    ));

    if (is_wp_error($response)) {
        wp_send_json_error(array('message' => 'Something went wrong.'));
    }

    $response_body = wp_remote_retrieve_body($response);
    $data = json_decode($response_body, true);

    wp_send_json_success($data);
}
add_action('wp_ajax_pulse_chatbot_ask_question', 'pulse_chatbot_ask_question');
add_action('wp_ajax_nopriv_pulse_chatbot_ask_question', 'pulse_chatbot_ask_question');

// Handle the AJAX request for submitting an email
function pulse_chatbot_submit_email() {
    $questionId = sanitize_text_field($_POST['questionId']);
    $email = sanitize_email($_POST['email']);

    // Here you would interact with the external chatbot API
    $response = wp_remote_post('http://vantagevsp.com/chatbot/submit-email', array(
        'method' => 'POST',
        'body' => json_encode(array(
            'questionId' => $questionId,
            'email' => $email
        )),
        'headers' => array(
            'Content-Type' => 'application/json'
        )
    ));

    if (is_wp_error($response)) {
        wp_send_json_error(array('message' => 'Something went wrong.'));
    }

    $response_body = wp_remote_retrieve_body($response);
    $data = json_decode($response_body, true);

    wp_send_json_success($data);
}
add_action('wp_ajax_pulse_chatbot_submit_email', 'pulse_chatbot_submit_email');
add_action('wp_ajax_nopriv_pulse_chatbot_submit_email', 'pulse_chatbot_submit_email');
?>
`
    }
  }),
  getters: {
    getSnippet: (state) => (framework) => {
      return state.codeSnippets[framework];
    }
  }
});
