<template>
  <div class="chat-container">
    <button class="chat-icon" @click="toggleChatWindow">
      <i v-if="!isChatOpen" style="font-size: 2rem;" class="bi bi-chat-dots"></i>
      <i v-if="isChatOpen" style="font-size: 2rem;" class="bi bi-chevron-down"></i>
    </button>

    <transition name="fade">
      <div v-if="isChatOpen" class="chat-window">
        <div class="chat-header">
          <p>Virtual Support Partner</p>
          <button class="close-btn" @click="toggleChatWindow">✖️</button>
        </div>

        <div class="chat-messages" ref="chatMessages">
          <div
            v-for="(msg, index) in messages"
            :key="index"
            :class="msg.isUser ? 'user-message' : 'agent-message'"
          >
            {{ msg.text }}
          </div>
        </div>

        <div v-if="isTyping" class="typing-indicator">
            <div class="typing-dots">
              <span></span><span></span><span></span>
            </div>
          </div>

        <div class="chat-input">
          <input
            v-model="newMessage"
            @keyup.enter="sendMessage"
            type="text"
            placeholder="Type your message..."
          />
          <button @click="sendMessage">
            <i style="font-size: 1.3rem;" class="bi bi-send send-btn"></i>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, watch, nextTick } from 'vue';

export default {
  name: 'ChatComponent',
  setup() {
    const isChatOpen = ref(false);
    const messages = ref([]);
    const newMessage = ref('');

    const isCollectingEmail = ref(false);
    const lastQuestionId = ref(null);

    const isTyping = ref(false);

    const chatMessages = ref(null);

    const toggleChatWindow = () => {
      isChatOpen.value = !isChatOpen.value;
    };

    const sendMessage = async () => {
      if (newMessage.value.trim() !== '') {
        const userMessage = newMessage.value;
        messages.value.push({ text: userMessage, isUser: true });
        newMessage.value = '';

        if (isCollectingEmail.value) {
          await submitEmail(userMessage);
          isCollectingEmail.value = false;
        } else {
          await sendQuestionToChatbot(userMessage);
        }
      }
    };

    const sendQuestionToChatbot = async (userMessage) => {
      try {
        isTyping.value = true;
        const response = await fetch('/chatbot/client/ask-question', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chatBotQuestion: userMessage,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to get a response from the chatbot');
        }

        const data = await response.json();
        messages.value.push({ text: data.message, isUser: false });

        if (data.emailNeeded) {
          messages.value.push({
            text: 'What is an email we can reach you at with a response to your question?',
            isUser: false,
          });
          lastQuestionId.value = data.questionId;
          isCollectingEmail.value = true;
        }
      } catch (error) {
        console.error('Error:', error);
        messages.value.push({
          text: 'Sorry, something went wrong. Please try again later.',
          isUser: false,
        });
      } finally {
        isTyping.value = false;
      }
    };

    const submitEmail = async (email) => {
      try {
        isTyping.value = true;
        const emailResponse = await fetch('/chatbot/client/submit-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            questionId: lastQuestionId.value,
            email: email,
          }),
        });

        if (!emailResponse.ok) {
          throw new Error('Failed to submit email');
        }
        const data = await emailResponse.json();

        messages.value.push({ text: data.message, isUser: false });
        lastQuestionId.value = null;
      } catch (error) {
        console.error('Error:', error);
        messages.value.push({
          text: 'Sorry, something went wrong. Please try again later.',
          isUser: false,
        });
        lastQuestionId.value = null;
      } finally {
        isTyping.value = false;
      }
    };

    watch(messages.value, async () => {
      await nextTick();
      if (chatMessages.value) {
        chatMessages.value.scrollTo({
          top: chatMessages.value.scrollHeight,
          behavior: 'smooth',
        });
      }
    });

    return {
      isChatOpen,
      messages,
      newMessage,
      toggleChatWindow,
      sendMessage,
      isTyping,
      chatMessages
    };
  },
};
</script>


<style scoped>
/* General Styles */
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* Chat Icon Button */
.chat-icon {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s, box-shadow 0.2s;
}

.chat-icon:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Chat Window */
.chat-window {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 330px;
  max-height: 400px;
  min-height:300px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: slide-up 0.3s ease-out;
}

/* Chat Header */
.chat-header {
  background-color: #007bff;
  color: #ffffff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header p {
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
}

.close-btn {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
}

.chat-messages {
  padding: 15px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f9f9f9;
}

.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

/* Message Bubbles */
.user-message,
.agent-message {
  max-width: 90%;
  padding: 10px 15px;
  margin-bottom: 12px;
  border-radius: 20px;
  word-wrap: break-word;
  position: relative;
  font-size: 0.95rem;
}

.user-message {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

.agent-message {
  background-color: #e9ecef;
  color: #333333;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}

.chat-input {
  display: flex;
  padding: 5px;
  border-top: 1px solid #ddd;
  background-color: #ffffff;
  height:60px;
  align-items: center;
}

.chat-input input {
  flex-grow: 1;
  padding-left: 15px;
  border-radius: 25px;
  border: 1px solid #ced4da;
  font-size: 0.85rem;
  outline: none;
  height:85%
}

.chat-input input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
  font-size: 1.2rem;
  transition: background-color 0.2s;
}

.chat-input button:hover {
  background-color: #0056b3;
}

/* Typing Indicator */
.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding:10px;
  background-color: #f9f9f9;
}

.typing-dots {
  display: flex;
}

.typing-dots span {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #999;
  border-radius: 50%;
  animation: typingDots 1s infinite ease-in-out;
}

.typing-dots span:nth-child(1) {
  animation-delay: 0s;
}

.typing-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Animations */
@keyframes slide-up {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes typingDots {
  0%,
  20% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 0.2;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>

