<template>
    <ring-loader :loading="loading" :color="color" size="200px"></ring-loader>
</template>
<script>
import { ref, onMounted, watch, inject } from 'vue';  
import { useRouter } from 'vue-router';
import RingLoader from 'vue-spinner/src/RingLoader.vue';
import { useUserStore } from '@/stores/user';
import { useToast } from 'vue-toastification';


export default {
  name: 'Loading',
  components: {
    RingLoader,
  },
  setup() {
    const router = useRouter();
    const isAuthenticated =  ref(false);
    const user = ref(null);

    const toast = useToast();

    const auth0Client = inject('auth0');

    const userObjectStore = useUserStore();

    const loading = ref(true);

    const color = ref('#000000')
    const height = ref('200px');
    const width = ref('200px');

    onMounted(async () => {
      try {
        loading.value = true;
        const response = await fetch('/auth/auth-status', {
          method: 'GET',
          credentials: 'include', // Include credentials (cookies) in the request
        });

        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.error, {
            position: "top-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          router.push('/')
        }else{
          const responseData = await response.json();
          toast.success(responseData.message, {
            position: "top-center",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        }
        /*loading.value = true;
        await auth0Client.handleRedirectCallback();
        isAuthenticated.value = await auth0Client.isAuthenticated();
        user.value = await auth0Client.getUser();
        userObjectStore.signIn(user.value)
        await createAccount();
        if(user.value && userObjectStore.isAuthenticated){
          router.push('/home');
        }else{
          router.push('/');
          alert('There was an issue authenticating. Try again later.')
        }*/
      } catch (error) {
        console.error('Initialization or auth check failed:', error);
      } finally {
        loading.value = false;
      }
    });
    
    const createAccount = async () => {
      try {
        const response = await fetch('/api/signup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: user.value.name,
            userId: user.value.sub,
            email: user.value.email
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Unknown error');
        }

      } catch (error) {
        alert(error);
      }
    };

    /*watch(isAuthenticated, async (newAuth) => {
      if (newAuth) {
        console.log('User is authenticated');
      }
    });*/


    /*watch(user, async (newUser) => {
      if (newUser && newUser.sub) {
        await createAccount();
        router.push('/home');
      }
    });*/

    return {
    loading,
    color,
    height,
    width,
    user,
    isAuthenticated
    };
  },
};
</script>
<style scoped>
.v-spinner {
  display: flex;
  justify-content: center;
  height: 100vh; 
}
</style>