<template>
  <div class="hero-container">
    <div class="hero-content">
      <div class="hero-statement">
        <h1 class="hero-title">VantageVSP</h1>
        <p class="hero-subtitle">Your Virtual Support Partner</p>
      </div>
      <button @click="loginRedirect" class="btn-default">
        My Dashboard
      </button>
    </div>
  </div>
  <SetupGuide></SetupGuide>
</template>

<script>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import { useToast } from 'vue-toastification';
import SetupGuide from '@/components/SetupGuide.vue';

export default {
  name: 'Login',
  components: {
    SetupGuide
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);
    const toast = useToast();

    const loginRedirect = () => {
      if (isAuthenticated.value) {
        router.push('/home');
      } else {
        toast.error("Please log in first.", {
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    };

    /*watch(
      () => authStore.user,
      (newUser) => {
        if (newUser) {
          window.location.href = '/home';
        }
      },
      { immediate: true, deep: true }
    );*/

    return {
      loginRedirect
    };
  },
};
</script>

<style>
.hero-container {
  position: relative;
  background-image: url('../assets/hero-background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.596);
  z-index: 1;
}

.hero-container > * {
  position: relative;
  z-index: 2;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.hero-statement {
  margin-bottom: 20px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: gray;
}

.btn-default {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 10px;
  width: 100%;
  max-width: 175px;
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: 70vh;
  }

  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.25rem;
  }

  .btn-default {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .btn-default {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}
</style>
