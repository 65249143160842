<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img alt="Logo" src="../assets/vantage-pulse-logo.png" class="navbar-logo" />
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Solutions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Community</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Resources</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Contact</a>
          </li>
        </ul>
        <div class="d-flex">
          <a v-if="!isAuthenticated" class="btn btn-default" style="margin-right:8px;" @click="logIn">Sign in</a>
          <a v-if="isAuthenticated" class="btn btn-default" style="margin-right:8px;" @click="logOut">Sign Out</a>
          <a v-if="!isAuthenticated" class="btn btn-default" href="#">Register</a>
          <router-link v-if="isAuthenticated" class="btn btn-default" to="/home">Dashboard</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref, onMounted, watch } from 'vue';
import { useAuthStore } from '@/stores/authStore';

export default {
  name: 'Navbar',
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);

    const logOut = () => {
      window.location.href = '/logout';
    };

    const logIn = () => {
      window.location.href = '/login';
    };

    watch(
      () => authStore.isAuthenticated,
      (newValue) => {
        isAuthenticated.value = newValue;
      }
    );

    onMounted(() => {
      isAuthenticated.value = authStore.isAuthenticated;
    });

    return {
      isAuthenticated,
      logOut,
      logIn,
    };
  },
};
</script>

<style scoped>
.navbar-logo {
  width: 80px;
  height: auto;
}

.navbar {
  margin: 0;
  padding-top: 10px;
  width: 100%;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.navbar-nav .nav-item .nav-link:hover {
  background-color: #91919194;
  color: white;
}

.navbar-nav .nav-item .nav-link {
  border-radius: 5px;
  padding: 12px;
}

.btn-default {
  width: fit-content;
}
</style>
