import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import { useToast } from 'vue-toastification';
import Home from '../pages/HomePage.vue';
import Login from '../pages/Login.vue';
import Loading from '../components/Loading.vue';
import Profile from '../pages/Profile.vue';
import Faq from '../pages/Faq.vue';
import Organizations from '@/pages/Organizations.vue';
import ManageUsers from '@/pages/ManageUsers.vue';
import LoggedOut from '@/pages/LoggedOut.vue';

// Lazy load route components
const routes = [
  { path: '/', component: Login },
  { path: '/home', component: Home, meta: { requiresAuth: true }},
  { path: '/loading', component: Loading },
  { path: '/profile', component: Profile },
  { path: '/organizations/faq/:organizationId', component: Faq, meta: { requiresAuth: true }},
  { path: '/organizations/manage-users/:organizationId', component: ManageUsers, meta: { requiresAuth: true }},
  { path: '/organizations', component: Organizations, meta: { requiresAuth: true }},
  { path: '/signout', component: LoggedOut }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const toast = useToast();
  const toastOptions = { position: 'bottom-left', timeout: 1300 };

  // Check authentication status if not already checked
  if (!authStore.isAuthenticated && !authStore.authChecked) {
    try {
      const { success, message } = await authStore.checkAuthStatus();
      if (success) {
        toast.success(message, toastOptions);
      } else if (to.path !== '/') {
        toast.error(message, toastOptions);
        return next('/');
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      return next('/');
    }
  }

  // Handle creation of account if user is authenticated but not in DB
  if (authStore.isAuthenticated && authStore.user && !authStore.userExistsInDB) {
    try {
      await authStore.getUserInfo();
      await createAccount(authStore.user);
    } catch (error) {
    }
  }

  // Fetch customer ID and subscription info when navigating to protected routes
  if (to.meta.requiresAuth && !authStore.customerId) {
    try {
      authStore.customerId = await fetchCustomerId();
      authStore.subscriptionInfo = await fetchSubscriptionInfo(authStore.customerId.customerId);
    } catch (error) {
      toast.error('Error fetching subscription details', toastOptions);
    }
  }

  next();
});

// Utility function for API requests
const apiRequest = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Unknown error');
    }
    return response.json();
  } catch (error) {
    console.error(`Error with API request to ${url}:`, error);
    throw error;
  }
};

// Create account if not exists
const createAccount = async (userData) => {
  const userObjectPost = {
    name: userData.name,
    userId: userData.sub,
    email: userData.email,
  };
  return apiRequest('/api/signup', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userObjectPost),
  });
};

// Fetch customer ID
const fetchCustomerId = async () => {
  return apiRequest('/auth/get-customerId', { method: 'GET' });
};

// Fetch subscription info
const fetchSubscriptionInfo = async (customerId) => {
  return apiRequest(`/customer/subscriptions?customerId=${encodeURIComponent(customerId)}`, { method: 'GET' });
};

export default router;
