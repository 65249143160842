<template>
    <SidebarMenu></SidebarMenu>
    <form @submit.prevent="">
    <div>
        <label for="userName">Name:</label>
        <div>
        <h1 id="userName">{{ user.name }}</h1>
        </div>
    </div>
    <div>
        <label for="organizationName">Company Name:</label>
        <input type="text" id="organizationName" v-model="organizationName" required>
    </div>
    <button type="submit">Submit</button>
    </form>
</template>
<script>
import { ref, onMounted } from 'vue';
import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  name: 'Home',
  components: {
    SidebarMenu
  },
  setup() {
    const {  user, isAuthenticated  } = useAuth0();

    const organizationName = ref('');

    

    return {
        user,
        organizationName,
    }

  }
}
</script>
<style scoped>

</style>