import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => ({
      isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
    }),
    actions: {
      signIn(userData) {
        this.isAuthenticated = true;
        this.userInfo = userData;
    
        // Persist to localStorage
        localStorage.setItem('isAuthenticated', 'true'); 
        localStorage.setItem('userInfo', JSON.stringify(userData));
      },
    
      signOut() {
        this.isAuthenticated = false;
        this.userInfo = null;
    
        // Remove from localStorage
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userInfo');
      },
    },
  });
