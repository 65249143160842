<template>
  <div class="sidebar-container d-flex flex-column">
    <div class="btn-group-vertical custom-btn-group" role="group" aria-label="VantagePulse Navigation">
      <button
        v-for="page in pages"
        :key="page.name"
        :class="['btn', 'btn-outline', 'custom-btn', 'text-start', { 'active-btn': activePage == page.name }]"
        @click="navigate(page)"
      >
        <i :class="page.icon"></i> {{ page.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, nextTick, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  setup() {
    const pages = ref([
      { name: 'Dashboard', link: '/home', icon: 'bi bi-house' },
      { name: 'Organizations', link: '/organizations', icon: 'bi bi-building' },
      { name: 'Knowledge Base', link: '/knowledge-base', icon: 'bi bi-book' },
      { name: 'Support', link: '/support', icon: 'bi bi-life-preserver' },
      { name: 'Settings', link: '/settings', icon: 'bi bi-gear' },
    ]);

    const activePage = ref('');
    const router = useRouter();
    const route = useRoute();

    // Watch the route changes to update the active page
    watch(
      () => route.path,
      (newPath) => {
        const matchedPage = pages.value.find(page => page.link === newPath);
        if (matchedPage) {
          activePage.value = matchedPage.name;
        }
      },
      { immediate: true }
    );

    const navigate = (page) => {
      activePage.value = page.name;
      console.log(page.name);
      console.log(activePage.value);
      nextTick(() => {
        router.push(page.link);
      });
    };

    return {
      pages,
      activePage,
      navigate,
    };
  },
};
</script>

<style scoped>
.custom-btn-group .custom-btn {
  border-radius: 50px;
  margin-bottom: 30px;
  padding: 10px 20px;
}
.custom-btn:hover {
  background-color: #195aa086 !important;
  color: white;
}

.btn-group-vertical .btn {
  border-radius: 50px !important;
}

.active-btn {
  background-color: #195aa0 !important;
  color: white !important;
  border-color: #195aa0 !important;
}

.custom-btn-group .custom-btn:last-child {
  margin-bottom: 0;
}

.sidebar-container {
  width: 80%;
  margin-left: 60px;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-right: #195aa0 3px solid;
}

i.bi {
  font-size: 1.2rem;
  margin-right: 10px;
}
</style>
