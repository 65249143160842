<template>
  <div class="main-container">
    <div class="users-header-container">
      <h1 class="faq-details">User Management For {{ dbOrgInfo.organizationName }}</h1>
    </div>

    <div class="tab-container">
      <div
        :class="['tab', { 'active-tab': selectedTab === 'users' }]"
        @click="selectedTab = 'users'"
      >
        Current Users
      </div>
      <div
        :class="['tab', { 'active-tab': selectedTab === 'invitations' }]"
        @click="selectedTab = 'invitations'"
      >
        Pending Invitations <span v-if="orgInvites.length > 0" class="invite-alert">{{ orgInvites.length }}</span>
      </div>
    </div>

    <div class="tab-content">
      <div v-if="selectedTab === 'users'">
        <table class="users-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in currentOrgUsers" :key="user.email">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.organization_role }}</td>
              <td>
                <i
                  v-if="user.organization_role !== 'Owner'"
                  @click="editOrgUser"
                  class="bi bi-pencil edit-user"
                ></i>
              </td>
              <td>
                <i
                  v-if="user.organization_role !== 'Owner'"
                  @click="deleteUserFromOrg(user.userId)"
                  class="bi bi-trash deny-invite"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="selectedTab === 'invitations'">
        <div class="invite-code">
          <p>To invite members to your organization, send them the following code:</p>
          <textarea disabled class="form-control editable-textarea" v-model="orgInviteCode"></textarea>
        </div>
        <table class="users-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Accept</th>
              <th>Decline</th>
            </tr>
          </thead>
          <tbody v-if="orgInvites.length > 0">
            <tr v-for="user in orgInvites" :key="user.email">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <i
                  @click="acceptOrgInvite(user.user_id, user.organization_id)"
                  class="bi bi-check-lg accept-invite"
                ></i>
              </td>
              <td>
                <i
                  @click="denyOrgInvite(user.user_id, user.organization_id)"
                  class="bi bi-ban deny-invite"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import { useToast } from 'vue-toastification';


export default {
  name: 'ManageUsers',
  setup() {
    const authStore = useAuthStore();
    const user = ref(authStore.user);
    const route = useRoute();

    const dbUserInfo = ref(null);
    const dbOrgInfo = ref(null);
    const selectedTab = ref('users');
    const currentOrgUsers = ref([]);
    const orgInvites = ref([]);
    const orgInviteCode = ref('');

    const toast = useToast();
    const toastOptions = { position: 'bottom-left', timeout: 1300 };

    const fetchWithAuth = async (url, options = {}) => {
      const response = await fetch(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error');
      }

      return response.json();
    };

    const getUserInfo = async () => {
      try {
        dbUserInfo.value = await fetchWithAuth(`/user/get-user-info?userId=${encodeURIComponent(user.value.sub)}`);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    const getOrganizationInfo = async () => {
      try {
        const orgInfo = await fetchWithAuth(`/organization/get-organization-info?userId=${encodeURIComponent(user.value.sub)}&orgId=${route.params.organizationId}`);
        dbOrgInfo.value = orgInfo[0];
        orgInviteCode.value = dbOrgInfo.value.inviteCode;
      } catch (error) {
        console.error('Failed to fetch organization info:', error);
      }
    };

    const getCurrentOrgUsers = async () => {
      try {
        currentOrgUsers.value = await fetchWithAuth(`/organization/get-organization-users?orgId=${route.params.organizationId}`);
      } catch (error) {
        console.error('Failed to fetch current organization users:', error);
      }
    };

    const getOrgInvitations = async () => {
      try {
        orgInvites.value = await fetchWithAuth(`/organization/get-organization-invites?orgId=${route.params.organizationId}`);
      } catch (error) {
        console.error('Failed to fetch organization invites:', error);
      }
    };

    const acceptOrgInvite = async (userId, orgId) => {
      try {
        await fetchWithAuth('/organization/accept-org-invite', {
          method: 'POST',
          body: JSON.stringify({ userId, orgId }),
        });
        await getOrgInvitations();
      } catch (error) {
        console.error('Failed to accept organization invite:', error);
        toast.error(`${error}`, toastOptions)
      }
    };

    const denyOrgInvite = async (userId, orgId) => {
      try {
        await fetchWithAuth('/organization/deny-org-invite', {
          method: 'POST',
          body: JSON.stringify({ userId, orgId }),
        });
        await getOrgInvitations();
      } catch (error) {
        console.error('Failed to deny organization invite:', error);
      }
    };

    const deleteUserFromOrg = async (userId) => {
      try {
        await fetchWithAuth('/organization/delete-user-from-org', {
          method: 'POST',
          body: JSON.stringify({ userId, orgId: dbOrgInfo.value.organizationId }),
        });
        await getCurrentOrgUsers();
      } catch (error) {
        console.error('Failed to delete user from organization:', error);
      }
    };

    const editOrgUser = () => {
      alert('Functionality not implemented yet.');
    };

    watch(selectedTab, (newTab) => {
      if (newTab === 'users') {
        getCurrentOrgUsers();
      } else if (newTab === 'invitations') {
        getOrgInvitations();
      }
    });

    onMounted(async () => {
      await getUserInfo();
      if (dbUserInfo.value) {
        await getOrganizationInfo();
      }
      await getCurrentOrgUsers();
      await getOrgInvitations();
    });

    return {
      dbOrgInfo,
      dbUserInfo,
      selectedTab,
      currentOrgUsers,
      orgInvites,
      acceptOrgInvite,
      denyOrgInvite,
      deleteUserFromOrg,
      editOrgUser,
      orgInviteCode,
    };
  },
};
</script>

<style scoped>
.main-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  color: #333333;
  width: 50%;
}

.users-header-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-container {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #ddd;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  color: #666;
  transition: color 0.3s ease;
}

.tab:hover {
  color: #000;
}

.active-tab {
  color: #000;
  border-bottom: 2px solid #000;
}

.tab-content {
  margin-top: 20px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.users-table th,
.users-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.users-table th {
  background-color: #f4f4f4;
}

.invite-alert {
  background-color: red;
  color: white;
  border-radius: 50px;
  padding: 4px 8px;
}

.accept-invite {
  color: green;
  cursor: pointer;
  margin-left: 25%;
  font-size: 28px;
}

.edit-user {
  cursor: pointer;
  margin-left: 25%;
  font-size: 20px;
}

.deny-invite {
  color: red;
  cursor: pointer;
  margin-left: 25%;
  font-size: 22px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .main-container {
    width: 90%;
    padding: 15px;
  }

  .tab {
    padding: 8px 15px;
    font-size: 14px;
  }

  .tab-container {
    flex-direction: column;
  }

  .tab {
    width: 100%;
    text-align: center;
  }

  .users-table th,
  .users-table td {
    padding: 8px;
    font-size: 14px;
  }

  .accept-invite,
  .edit-user,
  .deny-invite {
    margin-left: 10%;
    font-size: 20px;
  }
}

@media screen and (max-width: 576px) {
  .main-container {
    width: 100%;
    padding: 10px;
  }

  .tab {
    padding: 6px 10px;
    font-size: 12px;
  }

  .tab-content {
    margin-top: 15px;
  }

  .users-table th,
  .users-table td {
    padding: 6px;
    font-size: 12px;
  }

  .accept-invite,
  .edit-user,
  .deny-invite {
    margin-left: 5%;
    font-size: 18px;
  }
}

.editable-textarea {
  border: none;
  resize: none;
  outline: none;
  width: 50%;
  min-height: 50px;
}
</style>
