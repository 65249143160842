<template>
    <div class="container mt-5">
      <h2>Quick Start Guide</h2>
      <div class="main-container">
        <div class="tab-container">
            <div
                :class="['tab', { 'active-tab': selectedTab === 'frontend' }]"
                @click="selectedTab = 'frontend'"
            >
                Frontend
            </div>
            <div
                :class="['tab', { 'active-tab': selectedTab === 'backend' }]"
                @click="selectedTab = 'backend'"
            >
                Backend
            </div>
        </div>

        <div class="tab-content">
            <div v-if="selectedTab === 'frontend'" class="frontend-container">
              <div class="icon-img-container" @click="showCode('vuejs')">
                <font-awesome-icon id="vueJsIcon" :icon="['fab', 'vuejs']" class="frontend-icons" style="color:#68b281;"  />
                <label for="vueJsIcon">Vue.js</label>
              </div>
              <div class="icon-img-container" @click="showCode('react')">
                <font-awesome-icon id="reactIcon" :icon="['fab', 'react']" class="frontend-icons" style="color:#7ddfff;"/>
                <label for="reactIcon">React</label>
              </div>
              <div class="icon-img-container">
                <font-awesome-icon @click="showCode('wordpress')" id="wordpressIcon" :icon="['fab', 'wordpress']" class="frontend-icons" style="color:#00769d;"/>
                <label for="wordpressIcon">Wordpress</label>
              </div>
            </div>
            <div v-if="selectedTab === 'backend'" class="frontend-container">
              <div class="icon-img-container" @click="showCode('nodejs')">
                <font-awesome-icon id="nodeJsIcon" :icon="['fab', 'node']" class="frontend-icons" style="color:#68b281;"  />
                <label for="nodeJsIcon">Node.js</label>
              </div>
              <div class="icon-img-container">
                <font-awesome-icon @click="showCode('wordpress_backend')" id="wordpressIcon" :icon="['fab', 'wordpress']" class="frontend-icons" style="color:#00769d;"/>
                <label for="wordpressIcon">Wordpress</label>
              </div>
            </div>
        </div>
        <transition name="fade-slide">
          <ol v-if="selectedFramework == 'vuejs' && selectedTab === 'frontend'">
            <li>
              The <strong>ChatComponent</strong> is a reusable Vue component that enables a chat interface in your application. The component allows users to send messages to a chatbot and, if necessary, provide their email address for follow-up.
            </li>
            <li>
              To use the <strong>ChatComponent</strong>, first install the necessary dependencies. If you want to use Bootstrap Icons for the chat icon, install them with:
              <div class="code-display"><pre><code>npm install bootstrap-icons</code></pre></div>
            </li>
            <li>
              Once the dependencies are installed, import the <strong>ChatComponent</strong> into your <strong>App.vue</strong> or any other parent component where you want the chat icon to appear:
              <div class="code-display"><pre><code>import ChatComponent from './components/ChatComponent.vue';</code></pre></div>
            </li>
            <li>
              Add the <strong>&lt;ChatComponent /&gt;</strong> tag within your template to display the chat icon on your page. For example, in <strong>App.vue</strong>:
              <div class="code-display">
                <pre><code>
                  &lt;template&gt;
                    &lt;div id="app"&gt;
                      &lt;ChatComponent /&gt;
                      &lt;!-- Other components or content --&gt;
                    &lt;/div&gt;
                  &lt;/template&gt;
                </code></pre>
              </div>
            </li>
            <li>
              The component sends user messages to your backend via the <strong>/chatbot/client/ask-question</strong> route. This route expects a POST request with the user's question:
              <div class="code-display"><pre><code>
                {
                  "chatBotQuestion": "User's question"
                }
              </code></pre></div>
              From this backend endpoint, you will make a request to VantageVSP's chatbot endpoint which will return a response from our chatbot (Find more details about this once you choose the backend framework you are using). If the chatbot requires an email for follow-up, the backend will prompt the user to provide one.
            </li>
            <li>
              If the chatbot requests an email, the <strong>/chatbot/client/submit-email</strong> route is used to send the email address along with the question ID. The backend should then store this information for further communication:
              <div class="code-display"><pre><code>
                {
                  "questionId": "unique-question-id",
                  "email": "user@example.com"
                }
              </code></pre></div>
            </li>
            <li>
              Finally, run your application to see the chat icon on your page. You can now interact with the chatbot, and the component will handle sending messages and collecting emails as necessary.
            </li>
            <li>
              You can customize the appearance and functionality of the <strong>ChatComponent</strong> by modifying the styles and methods within the component file to better fit your application's design and requirements.
            </li>
          </ol>
        </transition>

        <transition name="fade-slide">
          <ol v-if="selectedFramework == 'nodejs' && selectedTab === 'backend'">
            <li>
              The backend will be responsible for processing the chatbot's interactions by handling user questions and managing follow-up communications via email. Begin by setting up your Node.js environment and ensure that you have the necessary dependencies installed. You will need <strong>express</strong> and <strong>node-fetch</strong> for handling HTTP requests. Install them with:
              <div class="code-display"><pre><code>npm install express node-fetch</code></pre></div>
            </li>
            <li>
              Create a new router file in your <strong>routes</strong> directory (e.g., <strong>chatbot.js</strong>) where you will define the routes that handle chatbot requests. Start by importing the required modules:
              <div class="code-display"><pre><code>
                const express = require('express');
                const fetch = require('node-fetch');
                const router = express.Router();
              </code></pre></div>
            </li>
            <li>
              Define the <strong>/client/ask-question</strong> POST route, which will receive the user's question from the frontend. This route should send the question to VantageVSP's chatbot endpoint and return the chatbot's response back to the frontend. Here's how you define this route:
              <div class="code-display"><pre><code>
    router.post('/client/ask-question', async (req, res) => {
        try {
            const { chatBotQuestion } = req.body;
            
            const response = await fetch('http://localhost:3000/chatbot/ask-question', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatBotQuestion,
                    apiKey: process.env.PULSE_CHAT_KEY 
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            res.status(200).json({ message: data.message, emailNeeded: data.emailNeeded, questionId: data.questionId });
        } catch (error) {
            console.error('Error:', error);
            res.status(500).json({ error: 'Internal server error' });
        }
    });
              </code></pre></div>
              This route handles the entire process of sending the question to the chatbot and returning the chatbot's reply to the user. It also checks if an email is needed for follow-up and includes a unique <strong>questionId</strong> for tracking the conversation.
            </li>
            <li>
              Next, define the <strong>/client/submit-email</strong> POST route, which will be used to handle email submissions when the chatbot requests an email. This route sends the email and the corresponding question ID to the backend:
              <div class="code-display"><pre><code>
  router.post('/client/submit-email', async (req, res) => {
      try {
          const { questionId, email } = req.body;
          
          const response = await fetch('http://localhost:3000/chatbot/submit-email', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  questionId: questionId,
                  email: email 
              })
          });

          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          res.status(200).json({ message: data.message });
      } catch (error) {
          console.error('Error:', error);
          res.status(500).json({ error: 'Internal server error' });
      }
  });
              </code></pre></div>
              This route ensures that when the chatbot requires further communication via email, the user's email address is securely stored along with the associated question ID.
            </li>
            <li>
              Once your routes are defined, ensure they are connected to your main server file (e.g., <strong>app.js</strong> or <strong>server.js</strong>). Import the router and use it in your application:
              <div class="code-display"><pre><code>
              const chatbotRoutes = require('./routes/chatbot');

              app.use('/chatbot', chatbotRoutes);
              </code></pre></div>
              This step integrates the chatbot routes into your application, allowing the frontend to interact with the chatbot via the defined endpoints.
            </li>
            <li>
              Finally, make sure to handle any environment variables (like <strong>PULSE_CHAT_KEY</strong>) securely by storing them in a <strong>.env</strong> file and accessing them via <strong>process.env</strong>. Run your Node.js application to start handling chatbot interactions. The backend will now correctly process user questions, forward them to the chatbot, and manage any necessary follow-up through email.
            </li>
          </ol>
        </transition>

        <transition name="fade-slide">
          <div v-if="selectedFramework == 'react' && selectedTab === 'frontend'">
            <ol>
              <li>
                The <strong>ChatComponent</strong> is a reusable React component that enables a chat interface in your application. The component allows users to send messages to a chatbot and, if necessary, provide their email address for follow-up.
              </li>
              <li>
                To use the <strong>ChatComponent</strong>, first install the necessary dependencies. If you want to use Bootstrap Icons for the chat icon, install them with:
                <div class="code-display"><pre><code>npm install bootstrap-icons</code></pre></div>
              </li>
              <li>
                Once the dependencies are installed, import the <strong>ChatComponent</strong> into your <strong>App.js</strong> or any other parent component where you want the chat icon to appear:
                <div class="code-display"><pre><code>import ChatComponent from './components/ChatComponent';</code></pre></div>
              </li>
              <li>
                Add the <strong>&lt;ChatComponent /&gt;</strong> tag within your JSX to display the chat icon on your page. For example, in <strong>App.js</strong>:
                <div class="code-display">
                  <pre><code>
                    function App() {
                      return (
                        &lt;div className="App"&gt;
                          &lt;ChatComponent /&gt;
                          &lt;!-- Other components or content --&gt;
                        &lt;/div&gt;
                      );
                    }
                  </code></pre>
                </div>
              </li>
              <li>
                The component sends user messages to your backend via the <strong>/chatbot/client/ask-question</strong> route. This route expects a POST request with the user's question:
                <div class="code-display"><pre><code>
                  {
                    "chatBotQuestion": "User's question"
                  }
                </code></pre></div>
                From this backend endpoint, you will make a request to VantageVSP's chatbot endpoint which will return a response from our chatbot. If the chatbot requires an email for follow-up, the backend will prompt the user to provide one.
              </li>
              <li>
                If the chatbot requests an email, the <strong>/chatbot/client/submit-email</strong> route is used to send the email address along with the question ID. The backend should then store this information for further communication:
                <div class="code-display"><pre><code>
                  {
                    "questionId": "unique-question-id",
                    "email": "user@example.com"
                  }
                </code></pre></div>
              </li>
              <li>
                Finally, run your application to see the chat icon on your page. You can now interact with the chatbot, and the component will handle sending messages and collecting emails as necessary.
              </li>
              <li>
                You can customize the appearance and functionality of the <strong>ChatComponent</strong> by modifying the styles and methods within the component file to better fit your application's design and requirements.
              </li>
            </ol>
          </div>
        </transition>

        <transition name="fade-slide">
          <ol v-if="selectedFramework == 'wordpress_backend' && selectedTab === 'backend'">
            
          </ol>
        </transition>

        <transition name="fade-slide">
          <div v-if="selectedCode" class="code-display">
            <div class="code-pointer"></div>
            <h3>Code Snippet</h3>
            <pre>
              <code :class="'language-' + codeLanguage" v-html="highlightedCode"></code>
            </pre>
          </div>
        </transition>
      </div>
    </div>
  </template>
  
  <script>
  import Prism from 'prismjs';
  import { ref, watch } from 'vue';
  import { useSnippetsStore } from '@/stores/setupCodeSnippets';
  
  export default {
    name: 'SetupGuide',
    setup() {
      const selectedTab = ref('frontend');
      const selectedCode = ref('');
      const codeLanguage = ref('javascript');
      const selectedFramework = ref('');
      const highlightedCode = ref('');
  
      const snippetsStore = useSnippetsStore();
  
      const showCode = (framework) => {
        selectedFramework.value = framework;
        selectedCode.value = snippetsStore.getSnippet(selectedFramework.value);
        
        // Check and adjust the language accordingly
        if (selectedFramework.value === 'vuejs') {
            codeLanguage.value = 'javascript'; 
        } else if (selectedFramework.value === 'react') {
            codeLanguage.value = 'javascript';
        }else if (selectedFramework.value == 'nodejs'){
          codeLanguage.value = 'javascript';
        } else {
            codeLanguage.value = 'html'; 
        }
        
        highlightedCode.value = Prism.highlight(selectedCode.value, Prism.languages[codeLanguage.value], codeLanguage.value);
      };
  


      watch(selectedTab, (newValue, oldValue) => {
            highlightedCode.value = ''
            selectedCode.value = ''
        });
  
      return {
        selectedTab,
        selectedCode,
        codeLanguage,
        showCode,
        highlightedCode,
        selectedFramework
      };
    },
  };
  </script>
  
  <style scoped>
.main-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 80px;
    font-size: 16px;
    color: #333333;
    min-width: 300px; /* Ensure a minimum width */
    width: 80%; /* Take 80% of the page width, for example */
    max-width: 1000px; /* Set a maximum width if needed */
    transition: width 0.3s ease-in-out; /* Smooth transition when resizing */
}
.tab-container {
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ddd;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #666;
    transition: color 0.3s ease;
}

.tab:hover {
    color: #000;
}

.active-tab {
    color: #000;
    border-bottom: 2px solid #000;
}

.tab-content {
    margin-top: 20px;
}

.frontend-icons {
  width:80px;
  height:80px;
}
.frontend-container {
  display:flex;
  justify-content: space-around;
}

.icon-img-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.code-display {
  background-color: #282c34;
  color: hsl(219, 14%, 71%);
  padding-top:10px;
  padding-left:5px;
  border-radius: 8px;
  font-family: 'Fira Code', 'Courier New', Courier, monospace;
  font-size: 14px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-x: auto;
  max-height: 600px;
}

.code-pointer {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #282c34;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.fade-slide-enter-active, .fade-slide-leave-active {
  transition: all 0.3s ease;
}

.fade-slide-enter-from, .fade-slide-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

code {
  white-space: pre-wrap;
  line-height: 1.5;
  color: #abb2bf;
}
  </style>
  