// stores/authStore.js
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false);
  const user = ref(null);
  const loading = ref(false);
  const router = useRouter();
  const authChecked = ref(false);

  const userPermissions = ref(null);

  const userExistsInDB = ref(false);

  const customerId = ref(null);
  const subscriptionInfo = ref(null);

  const checkAuthStatus = async () => {
    loading.value = true;
    try {
      const response = await fetch('/auth/auth-status', {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        authChecked.value = true;
        router.push('/');
        return { success: false, message: errorData.error };
      } else {
        const responseData = await response.json();
        isAuthenticated.value = true; 
        user.value = responseData.userObject;
        authChecked.value = true;
        return { success: true, message: responseData.message };
      }
    } catch (error) {
      console.error('Failed to check auth status:', error);
      return { success: false, message: 'An unexpected error occurred.' };
    } finally {
      loading.value = false;
    }
  };
  
  //check if user exists in DB
  const getUserInfo = async () => {
    try {
      if(user.value != null){
        const response = await fetch(`/user/get-user-info?userId=${encodeURIComponent(user.value.sub)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error((await response.json()).error || 'Unknown error')
        }else{
          userExistsInDB.value = true;
        }
      }
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };

  const validateSubscription = async (subscription) => {
    if (!user.value || !subscriptionInfo.value) {
      console.error('User or subscription information is missing.');
      return;
    }
  
    const payload = {
      userId: user.value.sub,
      subscriptionInfo: subscription,
    };
  
    try {
      const response = await fetch(`/customer/update-user-subscription`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error');
      } 
    } catch (error) {
      console.error('Failed to update subscription status:', error);
    }
  };
  
  const fetchPermissions = async () => {
    try {
      if(user.value != null){
        const response = await fetch(`/user/get-user-permissions?subscriptionTier=${encodeURIComponent(subscriptionInfo.value.subscriptionTier)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const responseData = await response.json();
        userPermissions.value = responseData;
        console.log('1')
      }
    } catch (error) {
      console.error('Failed to fetch user permissions:', error);
    }
  };

  watch(subscriptionInfo, async (newSubscription, oldSubscription) => {
    await validateSubscription(newSubscription);
    await fetchPermissions();
  })


  return {
    isAuthenticated,
    user,
    loading,
    checkAuthStatus,
    authChecked,
    getUserInfo,
    userExistsInDB,
    customerId,
    subscriptionInfo,
    userPermissions
  };
});
