<template>
    <p>You have been signed out</p>
</template>
  
<script>
import { ref, onMounted, watch } from 'vue';

export default {
    name: 'LoggedOut',
    setup() {
        onMounted(async () => {
            
        })
    }
}
</script>
  
<style scoped>



  </style>
  